import React, { useState, useRef, useEffect } from 'react'
import { UserProvider } from './context/UserContext'
import { Button } from "./components/ui/button"
import { Input } from "./components/ui/input"
import { Heart } from "lucide-react"
import { Analytics } from '@vercel/analytics/react';  // Add this import

function App() {
  const [messages, setMessages] = useState([])
  const [inputValue, setInputValue] = useState("")
  const [isTyping, setIsTyping] = useState(false)
  const messagesEndRef = useRef(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (inputValue.trim() === "") return

    // Add user message
    const userMessage = {
      id: Date.now(),
      text: inputValue,
      sender: "user",
    }
    setMessages((prevMessages) => [...prevMessages, userMessage])

    // Clear input immediately after submitting
    setInputValue("")

    // Show typing indicator
    setIsTyping(true)

    try {
      const response = await fetch('https://pregunta-git-26382f-lintoncamachoinvestments-gmailcoms-projects.vercel.app/api/index', {
        method: 'POST',  // This line is crucial
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          message: inputValue,
          history: messages.map(msg => ({
            role: msg.sender === "user" ? "user" : "assistant",
            content: msg.text
          }))
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Remove typing indicator and add bot message
      setIsTyping(false)
      const botMessage = {
        id: Date.now() + 1,
        text: data.reply,
        sender: "bot",
      }
      setMessages((prevMessages) => [...prevMessages, botMessage])
    } catch (error) {
      console.error('Error:', error);
      // Remove typing indicator and add error message
      setIsTyping(false)
      const errorMessage = {
        id: Date.now() + 1,
        text: "Lo siento, hubo un error al procesar tu mensaje. Por favor, inténtalo de nuevo.",
        sender: "bot",
      }
      setMessages((prevMessages) => [...prevMessages, errorMessage])
    }

    setInputValue("")
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(scrollToBottom, [messages])

  return (
    <UserProvider>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        maxWidth: '100%',
        margin: '0 auto',
        padding: '1rem',
        boxSizing: 'border-box',
        backgroundColor: '#f3f4f6'
      }}>
        <div style={{
          flexGrow: 1,
          marginBottom: '1rem',
          backgroundColor: 'white',
          borderRadius: '0.5rem',
          boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}>
          <div style={{
            flexGrow: 1,
            padding: '1rem',
            overflowY: 'auto'
          }}>
            {messages.length === 0 ? (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center'
              }}>
                <Heart style={{ width: '4rem', height: '4rem', color: '#ef4444', marginBottom: '1rem' }} />
                <h2 style={{ fontSize: '1.25rem', fontWeight: '600', marginBottom: '0.5rem' }}>
                  Bienvenido a PreguntaSalud.com
                </h2>
                <p style={{ marginBottom: '1rem' }}>
                  Este chatbot está diseñado para ayudarte con cualquier pregunta relacionada con la salud. Ingresa tus preguntas abajo y conversa con un experto.
                </p>
                <p style={{ fontSize: '0.875rem', color: '#4b5563' }}>
                  Recuerde, esta es una herramienta para ayudar con preguntas médicas y cualquier acción que se tome debe hacerse con el asesoramiento y la consulta de un médico.
                </p>
              </div>
            ) : (
              <>
                {messages.map((message) => (
                  <div
                    key={message.id}
                    style={{
                      marginBottom: '1rem',
                      textAlign: message.sender === "user" ? "right" : "left"
                    }}
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        padding: '0.5rem',
                        borderRadius: '0.375rem',
                        backgroundColor: message.sender === "user" ? "#3b82f6" : "#d1d5db",
                        color: message.sender === "user" ? "white" : "black"
                      }}
                    >
                      {message.text}
                    </span>
                  </div>
                ))}
                {isTyping && (
                  <div style={{
                    marginBottom: '1rem',
                    textAlign: 'left'
                  }}>
                    <span style={{
                      display: 'inline-block',
                      padding: '0.5rem',
                      borderRadius: '0.375rem',
                      backgroundColor: "#d1d5db",
                      color: "black"
                    }}>
                      <TypingIndicator />
                    </span>
                  </div>
                )}
              </>
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>
        <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '0.5rem' }}>
          <div style={{ flexGrow: 1, minWidth: 0 }}>
            <Input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder="Escriba su mensaje..."
              style={{ width: '100%' }}
            />
          </div>
          <Button type="submit" style={{ flexShrink: 0 }}>Enviar</Button>
        </form>
        <Analytics />  {/* Add this line at the end, inside the outermost div */}
      </div>
    </UserProvider>
  )
}

function TypingIndicator() {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '40px',
      height: '20px'
    }}>
      {[0, 1, 2].map((i) => (
        <div
          key={i}
          style={{
            width: '6px',
            height: '6px',
            backgroundColor: '#6b7280',
            borderRadius: '50%',
            margin: '0 2px',
            animation: 'typingAnimation 1s infinite',
            animationDelay: `${i * 0.3}s`
          }}
        />
      ))}
    </div>
  )
}

export default App
